<template>
  <span
    class="base-input flex"
    :class="{
      'p-inputgroup': $slots.leftAddon || $slots.rightAddon,
      'important-w-full': full
    }"
  >
    <slot name="leftAddon" />
    <span
      class="p-inputtext flex w-full middle"
      :class="{ 'p-inputgroup': $slots.leftIcon || $slots.rightIcon || clearable, 'p-invalid': error, inline }"
    >
      <slot name="leftIcon" />
      <prime-textarea
        v-if="multiline"
        v-bind="$attrs"
        v-model="model"
        :disabled="disabled"
        :readonly="readonly"
        rows="1"
        auto-resize
        class="important-w-full important-p-0 placeholder:color-truegray"
        @compositionstart="() => isComposition = true"
        @compositionend="handleCompositionend"
        @input="handleInput"
      />
      <prime-input-text
        v-else
        v-bind="$attrs"
        v-model="model"
        :disabled="disabled"
        :readonly="readonly"
        class="important-w-full placeholder:color-truegray"
        @compositionstart="() => isComposition = true"
        @compositionend="handleCompositionend"
        @input="handleInput"
      />
      <slot
        name="rightIcon"
        :clearable="clearable"
      >
        <i
          v-show="clearable"
          class="i-ri-close-circle-fill bg-[var(--text-color-secondary)] mx-2 cursor-pointer p-inputgroup-addon"
          @click="clear"
        />
      </slot>
    </span>
    <slot name="rightAddon" />
  </span>
</template>

<script setup lang="ts">
import { defineOptions, ref, computed, nextTick, useSlots } from 'vue'

defineOptions({
  name: 'BaseInput',
  inheritAttrs: false
})

const slots = useSlots()

const props = withDefaults(defineProps<{
  full?: boolean,
  readonly?: boolean,
  error?: boolean,
  disabled?: boolean,
  normalize?:(data: string) => string,
  inline?: boolean,
  multiline?: boolean
}>(), {
  full: true,
  readonly: false,
  error: false,
  disabled: false,
  normalize: undefined,
  inline: false,
  multiline: false
})

const emit = defineEmits<(e: 'change', event: Event) => void>()

const model = defineModel<string>({
  default: '',
  set (value) {
    if (!isComposition.value) return value
  }
})

const isComposition = ref(false)
const clear = () => {
  model.value = ''
}
const clearable = computed(() => !!model.value && !props.readonly && !props.disabled)
const isGroup = computed(() => !!slots.leftAddon || !!slots.rightAddon)
const handleCompositionend = (e: Event) => {
  isComposition.value = false
  model.value = (e.target as HTMLInputElement).value
}
const handleInput = (e: Event) => {
  emit('change', e)
  if (!props.normalize) return

  const target = e.target as HTMLInputElement
  const result = props.normalize(target.value)
  handleCompositionend(e)

  if (result !== target.value) {
    void nextTick(() => {
      model.value = result
      target.value = result
    })
  }
}
</script>

<style lang="scss" scoped>
.base-input {
  :deep(.middle) {
    @apply flex-row-center p-0 rounded-0 first:rounded-l-1.5 last:rounded-r-1.5;
    &.p-invalid {
      @apply border-[#e24c4c];
    }

    &.inline {
      @apply border-0 focus-within:shadow-none;
    }
    &:focus-within {
      @apply border-[#3B82F6];
    }
    > .p-inputtext {
      @apply border-none lh-none;
      &:enabled:focus {
        @apply shadow-none border-[#ced4da];
      }
    }
    > .p-inputgroup-addon {
      @apply border-none p-0 min-w-16px h-16px;
    }
  }
  :deep(.p-inputtext-sm + .pi) {
    @apply text-3.5;
  }
}
</style>
